<template>
    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4> Courses</h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard</router-link>
                    \ Courses
                  </div>
                </div>

              </div>
            </div>
            <div class="card-body">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <v-row>
                  <v-col cols="12" md="3">
                    <v-select
                        :loading="loading"
                        outlined
                        dense v-on:keyup.enter="getGrades"
                        label="Semester/year"
                        item-text="grade_title"
                        item-value="grade_id"
                        v-model="search.grade_id"
                        :items="gradeHistory">

                    </v-select>
                  </v-col>

                  <v-col cols="12" md="9" class="text-right">
                    <v-btn
                        @click.prevent="getGrades"
                        class="btn btn-primary"
                        style="color: #fff;"
                        :loading="loading">
                      <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <div class="table-responsive">
                <v-skeleton-loader
                    v-if="loading"
                    type="table-thead"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>
                <table class="table" v-if="!loading">
                  <thead>
                    <tr class="px-3">
                      <th class="px-3">Title</th>
                      <th>Subject code</th>
                      <th class="px-3">Program</th>
                      <th class="pr-3 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-show="grades.length > 0" v-for="(grade,gradeIndex) in grades" :key="gradeIndex">
                      <td class="px-3">
                        <span class="font-weight-medium">
                           {{grade.display_text_with_subject_code}}
                        </span>
                      </td>
                      <td>
                        <span class="font-weight-medium">
                            {{grade.subject_code }}
                          </span>
                      </td>
                      <td class="px-3">
                        <div class="text-secondary" v-if="grade.program_title">
                          <span class="font-weight-black">Program: </span> {{grade.program_title }}
                        </div>
                        <div class="text-secondary" v-if="grade.grade_title">
                          <span class="font-weight-black">Grade: </span> {{grade.grade_title }}
                        </div>
                      </td>
                      <td class="pr-0 text-center">
                        <template v-if="grade.lesson_plan && grade.lesson_plan.length">
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown"
                              no-caret
                              right
                              no-flip
                              text="Actions"

                          >
                            <template v-slot:button-content>
                              <a href="#" class="" data-toggle="dropdown">
                                <i class="flaticon-more-1"></i>
                              </a>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover">
                              <b-dropdown-text tag="div" class="navi-item" v-if="grade.lesson_plan[0]">
                                <a href="#" class="navi-link" @click="courseSummary(grade)">
                                    <span class="navi-icon">
                                      <i class="flaticon2-check-mark"></i>
                                    </span>
                                  <span class="navi-text"> Start course</span>
                                </a>
                              </b-dropdown-text>


                            </div>
                          </b-dropdown>
                        </template>
                        <div class="navi navi-hover" v-else>
                          <b-dropdown-text tag="div" class="navi-item">
                            <span class="btn btn-secondary">No Action Available</span>
                          </b-dropdown-text>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="grades.length == 0">
                      <td colspan="4" class="text-center">
                        <strong>No items found.</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </v-app>
</template>

<script>
    import CoursesService from "@/core/services/courses/CoursesService";
    import UserService from "@/core/services/user/UserService";
    import StudentSettingService from "@/core/services/user/student/StudentSettingService";
    import ExamService from "@/core/services/exam/ExamService";

    const courseService = new CoursesService();
    const userService = new UserService();
    const studentSettingService = new StudentSettingService();
    const examService = new ExamService;

    export default {
        name: "StudentCourses",
        data() {
            return {
                courses: [],
                grades: [],
                gradeHistory: [],
                user: null,
                studentSetting: null,
                loading: true,
                search: {
                    grade_id: null
                }
            }
        },
        mounted() {
            // this.getUser();
            this.getGrades();
            this.getGrade();
        },
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            }
        },
        methods: {
          courseSummary(item) {
            this.$router.push({
              name: "courses-summary",
              params: { lesson_plan_id:item.lesson_plan[0].id }
            });
          },
            getGrade() {
                this.loading = true;
                  examService
                  .getGrade()
                  .then(response => {
                    this.gradeHistory = response.data.history.grade_history;
                   }).catch(err => {

                  }).finally(() => {
                    this.loading = false;
                  })
            },
            grade_courses(grade) {
                let compulsory_courses = grade.compulsory_courses_info ? grade.compulsory_courses_info : [];
                let optional_courses = grade.optional_courses_info ? grade.optional_courses_info : [];
                let courses = [...compulsory_courses, ...optional_courses];
                return courses;
            },
            getUser() {
                userService.show().then(response => {
                    this.user = response.data.user;
                    this.user.is_active ? (this.user.is_active = true) : false;
                    this.user.is_email_verified ? (this.user.is_email_verified = true) : false;
                    this.studentSetting = this.user.setting ? this.user.setting : {}
                    // this.getGrades();
                });
            },
            getGrades() {
                this.loading = true;
                studentSettingService
                    .getGrades(this.search).then(response => {
                      if(response.data.data) {
                        this.grades = response.data.data;
                      }
                    this.loading = false;
                }).catch(error=>{
                    // console.log(error)
                }).finally(()=>{
                    this.loading = false;
                });
            },
            checkFirstAccordin(index) {
                return index == 0 ? true : false;
            },
        },
    }
</script>

<style scoped>

</style>
